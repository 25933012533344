import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Menu, Spin } from 'antd';
import StatsCards from './StatsCards'; // Certifique-se de que o caminho está correto
import Dashboard from './dashboard';
import DataTable from './DataTable';
import DashboardWeekChart from './DashboardWeekChart';
import AgeGenderChart from './AgeGenderChart';
import DashboardGA4 from './DashboardGA4';
import DataTableGA4 from './DataTableGA4';
import DataTableArticles from './DataTableArticles';
import Campanhas from './campanhas';
import WeeklyStats from './WeeklyStats';
import RadialBarChartComponent from './RadialBarChartComponent';
import Login from './Login';
import WeeklyConversionStats from './WeeklyConversionStats';
import MetaDashboard from './MetaDashboard';

const { Header, Content, Footer } = Layout;

function ProtectedRoute({ children }) {
  const localAdAccountId = localStorage.getItem('token');
  return localAdAccountId ? children : <Navigate to="/login" />;
}

function App() {
  const [selectedMenu, setSelectedMenu] = useState('Meta');
  const [stats, setStats] = useState({
    revenue: 0,
    invested: 0,
    purchases: 0,
    averageTicket: 0,
    roas: 0,
    dailyInvestedAvg: 0,
    dailyRevenueAvg: 0,
    todayInvested: 0,
    todayRevenue: 0,
    yesterdayInvested: 0,
    yesterdayRevenue: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch('https://ads-meta-867395045570.us-central1.run.app/api/insights/' + localAdAccountId, {
          headers: { Authorization: `Bearer ${localAdAccountId}` },
        });

        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }

        const data = await response.json();
      

        setStats({
          revenue: data.spend || 0,
          invested: data.invested || 0,
          purchases: data.purchase || 0,
          averageTicket: data.averageTicket || 0,
          roas: data.roas || 0,
          dailyInvestedAvg: data.dailyInvestedAvg || 0,
          dailyRevenueAvg: data.dailyRevenueAvg || 0,
          todayInvested: data.todayInvested || 0,
          todayRevenue: data.todayRevenue || 0,
          yesterdayInvested: data.yesterdayInvested || 0,
          yesterdayRevenue: data.yesterdayRevenue || 0,
        });

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        setLoading(false);
      }
    };

    fetchInsights();
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin size="large" />
      </div>
    );
  }

  const renderContent = () => {
    if (selectedMenu === 'Meta') {
      return (
        <div style={{ padding: 10, background: 'none', minHeight: 380 }}>
          <StatsCards stats={stats} />
          <WeeklyStats />
          <WeeklyConversionStats/>
          {/* <RadialBarChartComponent /> */}
          <DataTable />
          {/* <Campanhas /> */}
          {/* <DashboardWeekChart /> */}
        </div>
      );
    } else if (selectedMenu === 'GA4') {
      return (
        <div style={{ padding: 24, background: '#fff', minHeight: 380 }}>
          <DashboardGA4 />
          <DataTableGA4 />
          <DataTableArticles />
        </div>
      );
    } else if (selectedMenu === 'Analítico') {
      return (
        <div style={{ padding: 24, background: 'none', minHeight: 380 }}>
          <h2>Analítico</h2>
          <AgeGenderChart />
        </div>
      );
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#3a0c8d', padding: 0, display: 'flex', alignItems: 'center' }}>
        <div className="logo" style={{ marginLeft: '16px', marginRight: '20px', height: '48px' }}>
          <img src="https://umbrellatraffic.com.br/wp-content/uploads/2024/09/logo.webp" alt="Logo" style={{ height: '100%', width: 'auto', objectFit: 'contain' }} />
        </div>
        <Menu theme="dark" style={{ background: '#3a0c8d', flex: 1, color:'white' }} mode="horizontal" defaultSelectedKeys={['1']} onClick={(e) => setSelectedMenu(e.key)}>
          <Menu.Item key="Meta">Facebook</Menu.Item>
          {/* <Menu.Item key="GA4">GA4</Menu.Item> */}
          {/* <Menu.Item key="Analítico">Analítico</Menu.Item> */}
        </Menu>
      </Header>
      <Content style={{ padding: '0 10px', marginTop: 0, backgroundColor: '#0f0e1f', color: 'white' }}>
        {renderContent()}
      </Content>
      <Footer style={{ textAlign: 'center', background: '#3a0c8d', color:'white' }}>Umbrella Traffic technology ©2024 Created by Umbrella Traffic</Footer>
    </Layout>
  );
}

// Função de roteamento principal com proteção para a rota `/`
export default function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}
