import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Card, Row, Col, Spin } from 'antd';

const Dashboard = () => {
  const [insights, setInsights] = useState([]); // Estado para armazenar os dados dos insights
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [averagePurchaseValue, setAveragePurchaseValue] = useState(0); // Valor médio de `purchase_value`
  const [maxPurchaseValue, setMaxPurchaseValue] = useState(0); // Valor máximo de `purchase_value`

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`
        );
        const data = await response.json();

        // Obter o mês e ano atuais
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Gerar uma lista de todas as datas do mês atual
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const allDays = Array.from({ length: currentDay }, (_, i) => {
          const date = new Date(currentYear, currentMonth, i + 1);
          return {
            date: date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' }),
            date_start: date.toISOString().split('T')[0], // Para combinar com o formato da API
            purchase_value: 0,
            spend: 0,
          };
        });

        // Mapear dados reais e completar com dias vazios
        const dataWithDays = allDays.map(day => {
          const dayData = data.find(item => item.date_start === day.date_start);
          return dayData
            ? { 
                ...day,
                ...dayData, 
                purchase_value: parseFloat(dayData.purchase_value || 0), 
                spend: parseFloat(dayData.spend || 0) 
              }
            : day;
        });

        setInsights(dataWithDays);

        // Calcula a média do `purchase_value`
        const totalPurchaseValue = dataWithDays.reduce((acc, d) => acc + d.purchase_value, 0);
        const avgPurchase = totalPurchaseValue / dataWithDays.length;
        setAveragePurchaseValue(avgPurchase);

        // Calcula o valor máximo de `purchase_value` para ajustar o eixo Y
        const maxPurchase = Math.max(...dataWithDays.map(d => d.purchase_value));
        setMaxPurchaseValue(maxPurchase);

        setLoading(false); // Para de exibir o carregamento
      } catch (error) {
        console.error('Erro ao buscar dados:', error); // Exibe erros no console
        setLoading(false);
      }
    };

    fetchInsights(); // Chama a função para buscar os dados ao carregar o componente
  }, []);

  // Função para formatar os valores em reais
  const formatCurrency = (value) => {
    const numericValue = Number(value);
    return !isNaN(numericValue)
      ? `R$ ${numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      : value;
  };

  return (
    <div style={{ padding: '0px' }}>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" /> {/* Exibe o componente de carregamento */}
        </div>
      ) : (
        <Row gutter={16}>
          {/* Gráfico Stacked Area com Valor de Vendas e Valor Gasto */}
          <Col span={24}>
            <Card style={{background:'none', border: 'none'}} >
              <ResponsiveContainer width="100%" height={220} style={{backgroundColor: 'none'}}>
                <AreaChart
                  data={insights}
                  margin={{ top: 60, right: 30, left: 20, bottom: 0 }} // Ajusta a margem para não cortar o gráfico
                >
                  {/* Definindo o filtro SVG para o efeito neon */}
                  <defs>
                    <filter id="neon" x="-50%" y="-50%" width="200%" height="200%">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="coloredBlur"/> {/* Desfoque mais forte */}
                      <feMerge>
                        <feMergeNode in="coloredBlur"/>
                        <feMergeNode in="SourceGraphic"/>
                      </feMerge>
                    </filter>
                  </defs>

                  <XAxis 
                    dataKey="date"
                  />

                  <YAxis 
                    tickFormatter={(value) => formatCurrency(value)} 
                    domain={[0, maxPurchaseValue * 1.1]} // Definir o domínio do eixo Y com base no valor máximo
                  />
                  
                  <Tooltip 
                    formatter={(value) => formatCurrency(value)} // Formatar valores em reais no tooltip
                  />
                  
                  {/* Área para Valor de Vendas */}
                  <Area 
                    type="monotone" 
                    dataKey="purchase_value"
                    stroke="#8884d8"
                    fill="#4a148c"
                    animationDuration={2000}
                    strokeWidth={2}    /* Espessura da linha */
                    style={{ filter: 'url(#neon)' }}  /* Aplicação do filtro de neon */
                  />
                  
                  {/* Área para Valor Gasto (separado do valor de faturamento) */}
                  <Area 
                    type="monotone" 
                    dataKey="spend" 
                    stroke="#fe8400"  /* Neon verde */
                    fill="#f8b100"   /* Preenchimento semitransparente para combinar com o neon */
                    animationDuration={2000}
                    strokeWidth={2}    /* Espessura maior da linha para destacar mais */
                    style={{ filter: 'url(#neon)' }}  /* Aplicação do filtro de neon */
                  />

                  {/* Linha de Referência para a média de vendas (`purchase_value`) */}
                  <ReferenceLine
                    y={averagePurchaseValue}
                    label={`Média de Vendas: ${formatCurrency(averagePurchaseValue)}`}
                    stroke="#cf14b7"
                    strokeDasharray="3 3"
                    style={{color: '#cf14b7'}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Dashboard;
