import React, { useState, useEffect } from 'react';
import { Table, Spin, Button } from 'antd';
import axios from 'axios'; // Para fazer requisições ao backend

// Função para calcular a cor baseada no valor de cada célula
const getGradientColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return `rgba(74, 20, 140, ${percentage})`; // Usando roxo com opacidade
};

// Função para calcular a cor do texto (preto ou branco) dependendo da luminosidade do fundo
const getTextColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return percentage > 0.5 ? '#FFF' : '#000'; // Texto branco se o fundo for escuro, preto se for claro
};

// Função para formatar a duração média da sessão (em segundos) para minutos e segundos
const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

// Colunas da tabela com as novas métricas
const columns = [
  {
    title: 'Região',
    dataIndex: 'region',
    key: 'region',
    render: (text, record) => ({
      props: {
        style: {
          background: getGradientColor(record.purchaseRevenue, record.maxRevenue),
          color: getTextColor(record.purchaseRevenue, record.maxRevenue),
        },
      },
      children: text,
    }),
  },
  {
    title: 'Faturamento',
    dataIndex: 'purchaseRevenue',
    key: 'purchaseRevenue',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxRevenue),
          color: getTextColor(value, record.maxRevenue),
        },
      },
      children: `R$ ${Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
    }),
  },
  {
    title: 'Transações',
    dataIndex: 'transactions',
    key: 'transactions',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxTransactions),
          color: getTextColor(value, record.maxTransactions),
        },
      },
      children: value,
    }),
  },
  {
    title: 'Usuários Ativos',
    dataIndex: 'activeUsers',
    key: 'activeUsers',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxActiveUsers),
          color: getTextColor(value, record.maxActiveUsers),
        },
      },
      children: value,
    }),
  },
  {
    title: 'Sessões',
    dataIndex: 'sessions',
    key: 'sessions',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxSessions),
          color: getTextColor(value, record.maxSessions),
        },
      },
      children: value,
    }),
  },
  {
    title: 'Taxa de Rejeição',
    dataIndex: 'bounceRate',
    key: 'bounceRate',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxBounceRate),
          color: getTextColor(value, record.maxBounceRate),
        },
      },
      children: `${(value * 100).toFixed(2)}%`, // Exibe a taxa de rejeição em porcentagem
    }),
  },
  {
    title: 'Taxa de Engajamento',
    dataIndex: 'engagementRate',
    key: 'engagementRate',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxEngagementRate),
          color: getTextColor(value, record.maxEngagementRate),
        },
      },
      children: `${(value * 100).toFixed(2)}%`, // Exibe a taxa de engajamento em porcentagem
    }),
  },
  {
    title: 'Duração Média da Sessão',
    dataIndex: 'averageSessionDuration',
    key: 'averageSessionDuration',
    render: (value, record) => ({
      props: {
        style: {
          background: getGradientColor(value, record.maxAverageSessionDuration),
          color: getTextColor(value, record.maxAverageSessionDuration),
        },
      },
      children: formatDuration(value), // Formata a duração média da sessão em minutos e segundos
    }),
  },
];

const DataTableGA4 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState(''); // Estado para guardar a análise do ChatGPT
  const [loadingAnalysis, setLoadingAnalysis] = useState(false); // Controle do loading da análise

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ads-meta-867395045570.us-central1.run.app/api/ga4-insights'); // Ajuste a URL da API conforme necessário
        const insights = await response.json();

        // Agrupa os dados por região
        const groupedData = insights.reduce((acc, item) => {
          const region = item.region || 'Desconhecida';
          if (!acc[region]) {
            acc[region] = {
              region,
              purchaseRevenue: 0,
              transactions: 0,
              activeUsers: 0,
              sessions: 0,
              bounceRate: 0,
              engagementRate: 0,
              averageSessionDuration: 0,
            };
          }

          // Soma os valores para cada métrica por região
          acc[region].purchaseRevenue += parseFloat(item.purchaseRevenue || 0);
          acc[region].transactions += parseInt(item.transactions || 0);
          acc[region].activeUsers += parseInt(item.activeUsers || 0);
          acc[region].sessions += parseInt(item.sessions || 0);
          acc[region].bounceRate += parseFloat(item.bounceRate || 0);
          acc[region].engagementRate += parseFloat(item.engagementRate || 0);
          acc[region].averageSessionDuration += parseFloat(item.averageSessionDuration || 0);
          return acc;
        }, {});

        // Converte o objeto agrupado em um array de regiões únicas
        const groupedArray = Object.values(groupedData);

        // Ordena pelo faturamento (maior para menor)
        const sortedData = groupedArray.sort((a, b) => b.purchaseRevenue - a.purchaseRevenue);

        // Encontra os valores máximos para cada coluna
        const maxRevenue = Math.max(...sortedData.map(item => item.purchaseRevenue));
        const maxTransactions = Math.max(...sortedData.map(item => item.transactions));
        const maxActiveUsers = Math.max(...sortedData.map(item => item.activeUsers));
        const maxSessions = Math.max(...sortedData.map(item => item.sessions));
        const maxBounceRate = Math.max(...sortedData.map(item => item.bounceRate));
        const maxEngagementRate = Math.max(...sortedData.map(item => item.engagementRate));
        const maxAverageSessionDuration = Math.max(...sortedData.map(item => item.averageSessionDuration));

        // Adiciona os valores máximos a cada item para uso no render
        const transformedData = sortedData.map(item => ({
          ...item,
          maxRevenue,
          maxTransactions,
          maxActiveUsers,
          maxSessions,
          maxBounceRate,
          maxEngagementRate,
          maxAverageSessionDuration,
        }));

        setData(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Função para enviar os dados para análise no backend
  const analyzeData = async () => {
    setLoadingAnalysis(true);
    try {
      const result = await axios.post('https://ads-meta-867395045570.us-central1.run.app/api/analyze', { data }); // Envia os dados para o backend
      setAnalysis(result.data.response); // Atualiza a análise com a resposta do backend
    } catch (error) {
      console.error('Erro ao enviar os dados para análise:', error.response ? error.response.data : error.message);
      setAnalysis('Erro ao processar a análise.');
    } finally {
      setLoadingAnalysis(false);
    }
  };

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '0 auto' }} />;
  }

  return (
    <div>
      <Table
        style={{ margin: '0px', backgroundColor: 'white', borderRadius: '10px' }}
        columns={columns}
        dataSource={data}
        rowKey="region"
      />
      <Button onClick={analyzeData} type="primary" style={{ marginTop: '20px' }}>
        {loadingAnalysis ? 'Analisando...' : 'Analisar Dados'}
      </Button>
      {analysis && (
        <div style={{ marginTop: '20px' }}>
          <h3>Análise do ChatGPT:</h3>
          <p>{analysis}</p>
        </div>
      )}
    </div>
  );
};

export default DataTableGA4;
