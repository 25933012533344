import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const formatToBRL = (value) => `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

const RADIAN = Math.PI / 180;
const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => { total += v.value; });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="needle-circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path key="needle-path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} fill={color} />
  ];
};

const PieChartWithNeedle = ({ title, value }) => {
  const cx = 150;
  const cy = 120;
  const iR = 50;
  const oR = 80;
  const needleColor = "#d0d000";
  const data = [
    { name: 'Ruim', value: 1, color: '#FF0000' },
    { name: 'Médio', value: 1, color: '#FFA500' },
    { name: 'Bom', value: 8, color: '#00FF00' },
  ];

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
    width: '100%',
    minWidth: '300px',
  };

  return (
    <div style={gradientBorderStyle}>
      <Card
        title={title}
        headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
        bodyStyle={{ backgroundColor: '#1f1d39', color: 'white', paddingBottom: '0px', textAlign: 'center' }}
        bordered={false}
        style={{ width: '100%', minWidth: '300px', backgroundColor: '#1f1d39', borderRadius: '10px' }}
      >
        <PieChart width={250} height={200}>
          <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} stroke="none">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, needleColor)}
          <text x={cx} y={cy + 20} textAnchor="middle" dominantBaseline="central" fill="#FFFFFF" fontSize={24} fontWeight="bold">
            {`${value.toFixed(2)}%`}
          </text>
        </PieChart>
      </Card>
    </div>
  );
};

const CustomChart = ({ title, dataKeyBar, dataKeyLine, barColor, lineColor, formatAsCurrency, data }) => {
  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
    width: '100%',
    minWidth: '300px',
  };

  return (
    <div style={gradientBorderStyle}>
      <Card
        title={title}
        headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
        bodyStyle={{ backgroundColor: '#1f1d39', color: 'white', paddingBottom: '0px' }}
        bordered={false}
        style={{ width: '100%', minWidth: '300px', backgroundColor: '#1f1d39', borderRadius: '10px' }}
      >
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data}>
            <defs>
              <filter id="neon" x="-50%" y="-50%" width="200%" height="200%">
                <feGaussianBlur stdDeviation="4" result="coloredBlur" />
                <feMerge>
                  <feMergeNode in="coloredBlur" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(value) => formatAsCurrency ? formatToBRL(value) : value.toFixed(2)}
            />
            <Tooltip
              formatter={(value) => (formatAsCurrency ? formatToBRL(value) : value.toFixed(2))}
            />
            <Legend />
            <Bar dataKey={dataKeyBar} barSize={20} fill={barColor} />
            <Line
              type="monotone"
              dataKey={dataKeyLine}
              stroke={lineColor}
              strokeWidth={2}
              dot={{ r: 6, stroke: lineColor, strokeWidth: 2, fill: lineColor, style: { filter: 'url(#neon)' } }}
              activeDot={{ r: 8, stroke: lineColor, strokeWidth: 2, fill: lineColor, style: { filter: 'url(#neon)' } }}
              style={{ filter: 'url(#neon)' }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

const WeeklyConversionStats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taxaConversaoMediaTotal, setTaxaConversaoMediaTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`);
        const jsonResponse = await response.json();
        const apiData = jsonResponse.map((item) => ({
          name: item.date_start,
          ticketMedio: parseFloat(item.purchase_value) / parseInt(item.purchase, 10) || 0,
          taxaConversao: ((parseInt(item.purchase, 10) / parseInt(item.landing_page_view, 10)) * 100) || 0,
          custoCPC: parseFloat(item.cpc) || 0,
          taxaConversaoMedia: ((parseInt(item.purchase, 10) / parseInt(item.landing_page_view, 10)) * 100) || 0,
        }));

        const last5Days = apiData.slice(-5);
        setData(last5Days);

        const totalConversions = apiData.reduce((acc, item) => acc + item.taxaConversaoMedia, 0);
        const taxaConversaoMediaTotal = apiData.length ? totalConversions / apiData.length : 0;
        setTaxaConversaoMediaTotal(taxaConversaoMediaTotal);

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div style={{ padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', marginTop: '20px', margin: '30px' }}>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} lg={6}>
          <CustomChart
            title="Últimos Dias do Valor do Ticket Médio"
            dataKeyBar="ticketMedio"
            dataKeyLine="ticketMedio"
            barColor="#413ea0"
            lineColor="#00FF00"
            formatAsCurrency={true}
            data={data}
          />
        </Col>
        
        <Col xs={24} sm={12} lg={6}>
          <CustomChart
            title="Últimos Dias da Taxa de Conversão"
            dataKeyBar="taxaConversao"
            dataKeyLine="taxaConversao"
            barColor="#413ea0"
            lineColor="#00FFFF"
            formatAsCurrency={false}
            data={data}
          />
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <CustomChart
            title="Últimos Dias do Custo do CPC"
            dataKeyBar="custoCPC"
            dataKeyLine="custoCPC"
            barColor="#413ea0"
            lineColor="#FF00FF"
            formatAsCurrency={true}
            data={data}
          />
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <PieChartWithNeedle
            title="Taxa de Conversão Média Total"
            value={taxaConversaoMediaTotal}
          />
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyConversionStats;
