import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch('https://ads-meta-867395045570.us-central1.run.app/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      localStorage.setItem('token', data?.adAccountCode);
      navigate('/'); // Redireciona para a página principal
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Credenciais inválidas');
    }
  };

  return (
    <div className="login-container">
      {/* Lado esquerdo com a imagem de fundo */}
      <div className="login-image">
        {/* A imagem é definida no CSS como background-image */}
      </div>

      {/* Lado direito com o formulário */}
      <div className="login-form">
        <h2>Seja Bem vindo</h2>
        <p>Por favor coloque seu email e senha</p>

        <div className="input-group">
          <input
            type="text"
            placeholder="Exemplo@umbrella.com"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="login-options">
          <label>
            <input type="checkbox" />
            Lembrar da próxima vez
          </label>
        </div>

        <button className="login-button" onClick={handleLogin}>Login</button>

        <footer>
          <p>Umbrella Traffic technology ©2024 Created by Umbrella Traffic</p>
        </footer>
      </div>
    </div>
  );
}

export default Login;
