import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const formatToBRL = (value) => {
  return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const CustomChart = ({ title, dataKeyBar, dataKeyLine, barColor, lineColor, formatAsCurrency, data }) => (
  <Card
    title={title}
    headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
    bodyStyle={{ backgroundColor: '#1f1d39', color: 'white' }}
    bordered={false}
    style={{ width: '100%', minWidth: '300px' }}
  >
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart data={data}>
        <defs>
          <filter id="neon" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="4" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={formatAsCurrency ? formatToBRL : (value) => value}
        />
        <Tooltip formatter={(value) => (formatAsCurrency ? formatToBRL(value) : value)} />
        <Legend />

        <Bar dataKey={dataKeyBar} barSize={20} fill={barColor} />
        <Line
          type="monotone"
          dataKey={dataKeyLine}
          stroke={lineColor}
          strokeWidth={2}
          dot={{ r: 6, stroke: lineColor, strokeWidth: 2, fill: lineColor, style: { filter: 'url(#neon)' } }}
          activeDot={{ r: 8, stroke: lineColor, strokeWidth: 2, fill: lineColor, style: { filter: 'url(#neon)' } }}
          style={{ filter: 'url(#neon)' }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </Card>
);

const WeeklyStats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`
        );
        const jsonResponse = await response.json();

        const apiData = jsonResponse.map((item) => ({
          name: item.date_start,
          valor: parseFloat(item.purchase_value) || 0,
          compradores: parseFloat(item.purchase) || 0,
          conversas: parseFloat(item.add_to_cart) || 0,
          roas: parseFloat(item.purchase_roas?.[0]) || 0,
        }));
        console.log('Dados processados para o gráfico:', apiData);

        const last5Days = apiData.slice(-5);
        setData(last5Days);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div style={{ padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', marginTop: '20px', margin: '30px' }}>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} lg={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Faturamento por Dia da Semana"
              dataKeyBar="valor"
              dataKeyLine="valor"
              barColor="#413ea0"
              lineColor="#00FF00"
              formatAsCurrency={true}
              data={data}
            />
          </div>
        </Col>
        
        <Col xs={24} sm={12} lg={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Qnt. de Compras por Dia da Semana"
              dataKeyBar="compradores"
              dataKeyLine="compradores"
              barColor="#413ea0"
              lineColor="#00FFFF"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Adições ao carrinho"
              dataKeyBar="conversas"
              dataKeyLine="conversas"
              barColor="#413ea0"
              lineColor="#FF00FF"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="ROAS por Dia da Semana"
              dataKeyBar="roas"
              dataKeyLine="roas"
              barColor="#413ea0"
              lineColor="#FFFF00"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyStats;
