import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Statistic, Collapse } from 'antd';
import CountUp from 'react-countup';

const { Panel } = Collapse;

// Função para formatar valores em moeda
const formatCurrency = (value) => {
  return `R$ ${Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

// Função de formatação para a contagem animada
const formatter = (value) => <CountUp end={value} separator="," duration={5} />;

const DashboardGA4 = () => {
  const [stats, setStats] = useState({
    revenue: 0,
    purchases: 0,
    averageTicket: 0,
    dailyRevenueAvg: 0,
    todayRevenue: 0,
    yesterdayRevenue: 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGA4Insights = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
          // Objeto para agrupar os dados por data
          const groupedData = {};

          // Obter o mês e o ano atuais
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth(); // Mês atual (0-11)
          const currentYear = currentDate.getFullYear(); // Ano atual

          // Filtrar os dados apenas do mês atual
          const filteredData = data.filter((entry) => {
            const entryDate = new Date(`${entry.date.slice(0, 4)}-${entry.date.slice(4, 6)}-${entry.date.slice(6, 8)}`);
            return entryDate.getMonth() === currentMonth && entryDate.getFullYear() === currentYear;
          });

          // Agrupa os dados por data, somando as receitas e transações
          filteredData.forEach((entry) => {
            const date = entry.date; // Exemplo: "20241007"
            const revenue = parseFloat(entry.purchaseRevenue || 0);
            const transactions = parseInt(entry.transactions || 0);

            if (!groupedData[date]) {
              groupedData[date] = {
                purchaseRevenue: 0,
                transactions: 0,
              };
            }

            // Soma o faturamento e o número de compras para cada data
            groupedData[date].purchaseRevenue += revenue;
            groupedData[date].transactions += transactions;
          });

          // Converte o objeto agrupado de volta para um array de dias únicos
          const uniqueDays = Object.keys(groupedData).map((date) => ({
            date,
            purchaseRevenue: groupedData[date].purchaseRevenue,
            transactions: groupedData[date].transactions,
          }));

          let totalRevenue = 0;
          let totalPurchases = 0;
          let daysWithRevenue = 0;

          const sortedData = uniqueDays.sort((a, b) => new Date(a.date) - new Date(b.date));

          const today = sortedData[sortedData.length - 1]; // Último dia
          const yesterday = sortedData.length > 1 ? sortedData[sortedData.length - 2] : null; // Penúltimo dia, se disponível

          // Soma o faturamento e as compras, e conta os dias com faturamento
          sortedData.forEach((day) => {
            const dailyRevenue = parseFloat(day.purchaseRevenue || 0);
            totalRevenue += dailyRevenue;
            totalPurchases += parseInt(day.transactions || 0);

            if (dailyRevenue > 0) {
              daysWithRevenue++;
            }
          });

          // Calcula o ticket médio
          const averageTicket = totalPurchases > 0 ? totalRevenue / totalPurchases : 0;

          // Calcula o faturamento médio apenas para os dias com faturamento
          const dailyRevenueAvg = daysWithRevenue > 0 ? totalRevenue / daysWithRevenue : 0;

          // Verifica o faturamento de hoje e ontem
          const todayRevenue = today && today.purchaseRevenue ? parseFloat(today.purchaseRevenue).toFixed(2) : '0.00';
          const yesterdayRevenue = yesterday && yesterday.purchaseRevenue ? parseFloat(yesterday.purchaseRevenue).toFixed(2) : '0.00';

          setStats({
            revenue: totalRevenue.toFixed(2),
            purchases: totalPurchases,
            averageTicket: averageTicket.toFixed(2),
            dailyRevenueAvg: dailyRevenueAvg.toFixed(2),
            todayRevenue,
            yesterdayRevenue,
          });
        } else {
          // Se a API não retornar dados, garantimos que os valores fiquem em 0
          setStats({
            revenue: '0.00',
            purchases: 0,
            averageTicket: '0.00',
            dailyRevenueAvg: '0.00',
            todayRevenue: '0.00',
            yesterdayRevenue: '0.00',
          });
        }

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados do GA4:', error);
        setLoading(false);
      }
    };

    fetchGA4Insights();
  }, []);

  return (
    <div style={{ padding: '30px', backgroundColor: 'white', borderRadius: '10px' }}>
      <Row gutter={[16, 16]} justify="center">
        {/* Receita Captada */}
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card title="Receita Captada" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
            <Statistic
              title="Receita Captada"
              value={stats.revenue}
              precision={2}
              prefix="R$"
              formatter={formatter}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

        {/* Quantidade de Compras */}
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card title="Qnt. De Compras" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
            <Statistic
              title="Quantidade de Compras"
              value={stats.purchases}
              formatter={formatter}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

        {/* Ticket Médio */}
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card title="Ticket Médio" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
            <Statistic
              title="Ticket Médio"
              value={stats.averageTicket}
              precision={2}
              prefix="R$"
              formatter={formatter}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>

      {/* Collapse para mais detalhes */}
      <Collapse style={{ marginTop: '20px', border: 'none' }} accordion>
        <Panel header="Mais Detalhes" key="1">
          <Row gutter={[16, 16]} justify="center">
            {/* Faturamento Médio Diário */}
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card title="Faturamento médio diário" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
                <Statistic
                  title="Faturamento médio diário"
                  value={stats.dailyRevenueAvg}
                  precision={2}
                  prefix="R$"
                  formatter={formatter}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>

            {/* Faturamento Hoje */}
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card title="Faturamento Hoje" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
                <Statistic
                  title="Faturamento Hoje"
                  value={stats.todayRevenue}
                  precision={2}
                  prefix="R$"
                  formatter={formatter}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>

            {/* Faturamento Ontem */}
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card title="Faturamento Ontem" headStyle={{ backgroundColor: '#4A148C', color: '#FFF' }} bodyStyle={{ backgroundColor: '#EDE7F6' }}>
                <Statistic
                  title="Faturamento Ontem"
                  value={stats.yesterdayRevenue}
                  precision={2}
                  prefix="R$"
                  formatter={formatter}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

export default DashboardGA4;
