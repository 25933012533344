import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import axios from 'axios';

// Função para calcular a cor baseada no valor de cada célula (usando a lógica de temperatura)
const getGradientColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return `rgba(74, 20, 140, ${percentage})`; // Usando roxo com opacidade
};

// Função para calcular a cor do texto (preto ou branco) dependendo da luminosidade do fundo
const getTextColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return percentage > 0.5 ? '#FFF' : '#000'; // Texto branco se o fundo for escuro, preto se for claro
};

// Função para agrupar e somar os dados por itemName
const groupAndSumData = (data) => {
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.itemName]) {
      acc[item.itemName] = {
        itemName: item.itemName,
        itemsViewed: 0,
        itemsAddedToCart: 0,
        itemsPurchased: 0,
      };
    }
    acc[item.itemName].itemsViewed += parseInt(item.itemsViewed);
    acc[item.itemName].itemsAddedToCart += parseInt(item.itemsAddedToCart);
    acc[item.itemName].itemsPurchased += parseInt(item.itemsPurchased);
    return acc;
  }, {});

  // Converte o objeto de volta para um array
  return Object.values(groupedData);
};

// Colunas da tabela com as métricas de artigos e gradientes aplicados
const columns = (maxValues) => [
  {
    title: 'Nome do Artigo',
    dataIndex: 'itemName',
    key: 'itemName',
    render: (text) => text,
  },
  {
    title: 'Artigos Vistos',
    dataIndex: 'itemsViewed',
    key: 'itemsViewed',
    render: (value) => ({
      props: {
        style: {
          background: getGradientColor(value, maxValues.maxItemsViewed),
          color: getTextColor(value, maxValues.maxItemsViewed),
        },
      },
      children: value,
    }),
  },
  {
    title: 'Adicionados ao Carrinho',
    dataIndex: 'itemsAddedToCart',
    key: 'itemsAddedToCart',
    render: (value) => ({
      props: {
        style: {
          background: getGradientColor(value, maxValues.maxItemsAddedToCart),
          color: getTextColor(value, maxValues.maxItemsAddedToCart),
        },
      },
      children: value,
    }),
  },
  {
    title: 'Comprados',
    dataIndex: 'itemsPurchased',
    key: 'itemsPurchased',
    render: (value) => ({
      props: {
        style: {
          background: getGradientColor(value, maxValues.maxItemsPurchased),
          color: getTextColor(value, maxValues.maxItemsPurchased),
        },
      },
      children: value,
    }),
  },
];

const DataTableArticles = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ads-meta-867395045570.us-central1.run.app/api/ga4-article-insights');
        
        // Agrupa e soma os dados por itemName
        const groupedData = groupAndSumData(response.data);

        // Calcula os valores máximos para cada coluna
        const maxItemsViewed = Math.max(...groupedData.map(item => item.itemsViewed));
        const maxItemsAddedToCart = Math.max(...groupedData.map(item => item.itemsAddedToCart));
        const maxItemsPurchased = Math.max(...groupedData.map(item => item.itemsPurchased));

        // Prepara os valores máximos para passar para as colunas
        const maxValues = { maxItemsViewed, maxItemsAddedToCart, maxItemsPurchased };

        // Ordena os dados pelos mais vendidos (itemsPurchased)
        const sortedData = groupedData
          .map(item => ({ ...item, maxValues }))
          .sort((a, b) => b.itemsPurchased - a.itemsPurchased); // Ordena pelos mais vendidos

        setData(sortedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados de artigos:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '0 auto' }} />;
  }

  return (
    <div>
      <Table
        style={{ margin: '0px', backgroundColor: 'white', borderRadius: '10px' }}
        columns={columns(data[0]?.maxValues)} // Passa os valores máximos para as colunas
        dataSource={data}
        rowKey="itemName"
      />
    </div>
  );
};

export default DataTableArticles;
