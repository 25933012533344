import React, { useEffect, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import { Card, Row, Col, Spin } from 'antd';

// Definição das cores com um toque neon para cada sexo (roxo e azul neon)
const COLORS = ['#8A2BE2', '#00FFFF']; // Roxo neon para masculino, azul neon para feminino

// Função personalizada para os rótulos do gráfico
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white" // Texto branco para destacar no fundo escuro
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontWeight: 'bold', filter: 'url(#neon)' }} // Efeito neon no texto
    >
      {`${name} (${(percent * 100).toFixed(0)}%)`}
    </text>
  );
};

// Componente que renderiza os gráficos
const GenderPieChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ads-meta-867395045570.us-central1.run.app/api/demographics-gender-age');
        const result = await response.json();
        const transformedData = transformData(result); // Transforma os dados
        setData(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados demográficos:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Função para transformar os dados brutos em um formato adequado para o gráfico
  const transformData = (rawData) => {
    const totalSpendMale = rawData.reduce((acc, curr) => acc + (curr.gender === 'male' ? parseFloat(curr.spend) : 0), 0);
    const totalSpendFemale = rawData.reduce((acc, curr) => acc + (curr.gender === 'female' ? parseFloat(curr.spend) : 0), 0);
    const totalRevenueMale = rawData.reduce((acc, curr) => acc + (curr.gender === 'male' ? parseFloat(curr.purchase_value) : 0), 0);
    const totalRevenueFemale = rawData.reduce((acc, curr) => acc + (curr.gender === 'female' ? parseFloat(curr.purchase_value) : 0), 0);
    const totalImpressionsMale = rawData.reduce((acc, curr) => acc + (curr.gender === 'male' ? parseFloat(curr.impressions) : 0), 0);
    const totalImpressionsFemale = rawData.reduce((acc, curr) => acc + (curr.gender === 'female' ? parseFloat(curr.impressions) : 0), 0);

    return {
      spend: [
        { name: 'Masculino', value: totalSpendMale },
        { name: 'Feminino', value: totalSpendFemale }
      ],
      revenue: [
        { name: 'Masculino', value: totalRevenueMale },
        { name: 'Feminino', value: totalRevenueFemale }
      ],
      impressions: [
        { name: 'Masculino', value: totalImpressionsMale },
        { name: 'Feminino', value: totalImpressionsFemale }
      ]
    };
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <Row gutter={16}>
      {/* Card para Gasto */}
      <Col xs={24} md={8}>
        <Card 
          style={{ backgroundColor: '#1f1d38', color: 'white' }} 
          title="Gasto por Sexo" 
          headStyle={{ 
            backgroundColor: '#3A0D8E', // Cor de fundo roxa
            color: 'white', 
            textAlign: 'center', // Centralizando o texto do cabeçalho
            borderBottom: 'none' // Remover a linha branca abaixo do título
          }} 
          bordered={false} // Remove as bordas
        >
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <defs>
                {/* Filtro Neon */}
                <filter id="neon" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />
                  <feMerge>
                    <feMergeNode in="coloredBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>

              <Pie
                data={data.spend}
                cx="50%"
                cy="50%"
                startAngle={180} // Início do ângulo
                endAngle={0} // Fim do ângulo
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel} // Usa o rótulo personalizado
                style={{ filter: 'url(#neon)' }} // Efeito neon no gráfico
              >
                {/* Aplica cores diferentes para masculino e feminino */}
                {data.spend.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `R$ ${value.toFixed(2)}`} />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>

      {/* Card para Faturamento */}
      <Col xs={24} md={8}>
        <Card 
          style={{ backgroundColor: '#1f1d38', color: 'white' }} 
          title="Faturamento por Sexo" 
          headStyle={{ 
            backgroundColor: '#3A0D8E', // Cor de fundo roxa
            color: 'white', 
            textAlign: 'center', // Centralizando o texto do cabeçalho
            borderBottom: 'none' // Remover a linha branca abaixo do título
          }} 
          bordered={false} // Remove as bordas
        >
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.revenue}
                cx="50%"
                cy="50%"
                startAngle={180}
                endAngle={0}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel} // Usa o rótulo personalizado
                style={{ filter: 'url(#neon)' }} // Efeito neon no gráfico
              >
                {/* Aplica cores diferentes para masculino e feminino */}
                {data.revenue.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `R$ ${value.toFixed(2)}`} />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>

      {/* Card para Impressões */}
      <Col xs={24} md={8}>
        <Card 
          style={{ backgroundColor: '#1f1d38', color: 'white' }} 
          title="Impressões por Sexo" 
          headStyle={{ 
            backgroundColor: '#3A0D8E', // Cor de fundo roxa
            color: 'white', 
            textAlign: 'center', // Centralizando o texto do cabeçalho
            borderBottom: 'none' // Remover a linha branca abaixo do título
          }} 
          bordered={false} // Remove as bordas
        >
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.impressions}
                cx="50%"
                cy="50%"
                startAngle={180}
                endAngle={0}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel} // Usa o rótulo personalizado
                style={{ filter: 'url(#neon)' }} // Efeito neon no gráfico
              >
                {/* Aplica cores diferentes para masculino e feminino */}
                {data.impressions.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value}`} />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  );
};

export default GenderPieChart;
