import React, { useEffect, useState } from 'react';
import { Card, Statistic } from 'antd';
import CountUp from 'react-countup';
import Dashboard from './dashboard';
import MetaDashboard from './MetaDashboard'; // Importe o MetaDashboard

// Função de formatação para a contagem animada
const formatter = (value) => <CountUp end={value} separator="," duration={5} />;

const StatsCards = () => {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aggregatedStats, setAggregatedStats] = useState({
    revenue: 0,
    invested: 0,
    purchases: 0,
    averageTicket: 0,
    roas: 0,
    dailyInvestedAvg: 0,
    dailyRevenueAvg: 0,
    todayInvested: 0,
    todayRevenue: 0,
    yesterdayInvested: 0,
    yesterdayRevenue: 0,
  });

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const localAdAccountId = localStorage.getItem('token');
        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`
        );

        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }

        const data = await response.json();

        // Adiciona o dia atual com valores zerados, se ele não estiver presente
        const currentDate = new Date().toISOString().split('T')[0];
        const lastDateInData = data[data.length - 1]?.date_start;

        if (lastDateInData !== currentDate) {
          data.push({
            date_start: currentDate,
            spend: "0",
            purchase_value: "0",
            purchase: "0",
            purchase_roas: ["0"],
          });
        }

        setInsights(data);
        aggregateStats(data); // Agrega os dados
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, []);

  const aggregateStats = (data) => {
    const totalDays = data.length;

    const totalStats = data.reduce(
      (acc, day) => {
        acc.invested += parseFloat(day.spend) || 0;
        acc.revenue += parseFloat(day.purchase_value) || 0;
        acc.purchases += parseInt(day.purchase, 10) || 0;
        acc.roas += parseFloat(day.purchase_roas[0]) || 0;
        return acc;
      },
      {
        invested: 0,
        revenue: 0,
        purchases: 0,
        roas: 0,
      }
    );

    totalStats.roas = totalDays ? totalStats.roas / totalDays : 0;
    totalStats.averageTicket = totalStats.purchases ? totalStats.revenue / totalStats.purchases : 0;
    totalStats.dailyInvestedAvg = totalDays ? totalStats.invested / totalDays : 0;
    totalStats.dailyRevenueAvg = totalDays ? totalStats.revenue / totalDays : 0;

    const todayData = data[data.length - 1] || {};
    const yesterdayData = data[data.length - 2] || {};

    totalStats.todayInvested = parseFloat(todayData.spend) || 0;
    totalStats.todayRevenue = parseFloat(todayData.purchase_value) || 0;
    totalStats.yesterdayInvested = parseFloat(yesterdayData.spend) || 0;
    totalStats.yesterdayRevenue = parseFloat(yesterdayData.purchase_value) || 0;

    setAggregatedStats(totalStats);
  };

  const {
    revenue,
    invested,
    purchases,
    averageTicket,
    roas,
    dailyInvestedAvg,
    dailyRevenueAvg,
    todayInvested,
    todayRevenue,
    yesterdayInvested,
    yesterdayRevenue,
  } = aggregatedStats;

  const cardHeadStyle = { backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' };
  const cardBodyStyle = { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center', padding: 0 };
  const positiveValueStyle = { color: 'white' };
  const negativeValueStyle = { color: 'white' };

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
  };

  if (loading) {
    return <p>Carregando dados...</p>;
  }

  return (
    <div style={{ padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', margin: '30px'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <CardItem title="Receita Captada" value={revenue} prefix="R$" valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
        <CardItem title="Valor Investido" value={invested} prefix="R$" valueStyle={negativeValueStyle} gradientBorderStyle={gradientBorderStyle} />
        <CardItem title="Qnt. De Compras" value={purchases} valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
        <CardItem title="Ticket Médio" value={averageTicket} prefix="R$" valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
        <CardItem title="ROAS" value={roas} valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
      </div>

      <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'row' }}>
        <div style={{ flex: 1, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '20px', marginRight: '20px' }}>
          <CardItem title="Investimento Médio Diário" value={dailyInvestedAvg} prefix="R$" valueStyle={negativeValueStyle} gradientBorderStyle={gradientBorderStyle} />
          <CardItem title="Faturamento Médio Diário" value={dailyRevenueAvg} prefix="R$" valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
          <CardItem title="Investimento Hoje" value={todayInvested} prefix="R$" valueStyle={negativeValueStyle} gradientBorderStyle={gradientBorderStyle} />
          <CardItem title="Faturamento Hoje" value={todayRevenue} prefix="R$" valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
          <CardItem title="Investimento Ontem" value={yesterdayInvested} prefix="R$" valueStyle={negativeValueStyle} gradientBorderStyle={gradientBorderStyle} />
          <CardItem title="Faturamento Ontem" value={yesterdayRevenue} prefix="R$" valueStyle={positiveValueStyle} gradientBorderStyle={gradientBorderStyle} />
        </div>

        {/* Contêiner para Dashboard e MetaDashboard lado a lado */}
        <div style={{ flex: 2, display: 'flex', alignItems: 'stretch' }}>
          {/* Gráfico de linha, ocupando maior parte do espaço */}
          <div style={{ flex: 1, marginRight: '20px' }}>
            <div style={gradientBorderStyle}>
              <Card title="Desempenho Diário (Vendas e Gasto)" headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered={false}>
                <Dashboard />
              </Card>
            </div>
          </div>

          {/* Componente MetaDashboard com largura fixa */}
          <div style={{ width: '130px', marginRight:'10px' }}>
            <MetaDashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente auxiliar para renderizar os cards de estatísticas
const CardItem = ({ title, value, prefix, valueStyle, gradientBorderStyle }) => {
  return (
    <div style={{ flex: 1, marginRight: '10px' }}>
      <div style={gradientBorderStyle}>
        <Card title={title} headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }} bodyStyle={{ backgroundColor: '#1f1d39', color: 'white', textAlign: 'center', padding: 0 }} bordered={false}>
          <Statistic value={value} precision={2} prefix={prefix} formatter={formatter} valueStyle={valueStyle} />
        </Card>
      </div>
    </div>
  );
};

export default StatsCards;
